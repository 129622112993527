<template>
  <div class="returnCardBox">
    <!-- 筛选条件 -->
    <div class="searchBox">
      <el-row>
        <el-col :span="5" class="flex items-center">
          <div class="flex-none mr-8px">联系姓名:</div>
          <el-input v-model="queryParams.data.contactName" placeholder="请输入联系姓名" />
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">联系手机号:</div>
          <el-input v-model="queryParams.data.contactMobile" placeholder="请输入手机号" />
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">会员ID:</div>
          <el-input v-model="queryParams.data.memberId" placeholder="请输入会员ID" />
        </el-col>
      </el-row>
      <el-row class="mt20 mb-20px">
        <el-col :span="5" class="flex items-center">
          <div class="flex-none mr-8px">处理状态:</div>
          <el-select v-model="queryParams.data.status" placeholder="请选择处理状态">
            <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">会员等级:</div>
          <el-select v-model="queryParams.data.gradeNames" placeholder="请选择会员等级" multiple collapse-tags>
            <el-option v-for="item in leaveList" :key="item" :label="item" :value="item" />
          </el-select>
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">退卡原因:</div>
          <el-select v-model="queryParams.data.reason" placeholder="请选择退卡原因">
            <el-option v-for="item in reasonList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mt20 mb-20px">
        <el-col :span="6" class="flex items-center">
          <div class="flex-none mr-8px">提交日期:</div>
          <el-config-provider :locale="zhCn">
            <el-date-picker
              v-model="createTime"
              :default-time="[new Date('1 00:00:00'), new Date('1 23:59:59')]"
              end-placeholder="结束日期"
              start-placeholder="开始日期"
              type="daterange"
              value-format="YYYY-MM-DD HH:mm:ss"
              @change="timeChange"
            />
          </el-config-provider>
        </el-col>
        <el-col :span="10" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">最近消费门店:</div>
          <el-cascader :key="refreshItem" style="width: 100%;" v-model="shopValue" :options="options" @change="handleChange" :props="props" placeholder="请选择最近消费门店" collapse-tags filterable />
        </el-col>
        <el-col :span="2" class="ml-20px flex">
          <el-button type="primary" @click="getTabList">查 询</el-button>
          <el-button @click="resetTable">重 置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="exportBox">
      <el-button @click="exportExcel" icon="el-icon-download" type="primary" size="small">导 出</el-button>
    </div>
    <!-- table表格 -->
    <el-table stripe :data="returnCardList" :header-cell-style="{ background: 'rgb(242, 242, 242)', color: '#333' }" v-loading="tableLoading" element-loading-text="拼命加载中...">
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column prop="contactName" label="联系姓名" show-overflow-tooltip />
      <el-table-column prop="memberGradeName" label="会员等级" show-overflow-tooltip />
      <el-table-column prop="contactMobile" label="联系手机号" />
      <el-table-column prop="memberId" label="会员ID" show-overflow-tooltip />
      <el-table-column label="退卡原因" show-overflow-tooltip>
        <template #default="scope">
          <span v-if="scope.row.reason == 1">个人原因</span>
          <span v-if="scope.row.reason == 2">服务不佳</span>
          <span v-if="scope.row.reason == 3">产品或项目问题</span>
          <span v-if="scope.row.reason == 4">未达到预期效果</span>
          <span v-if="scope.row.reason == 5">门店接待服务不满</span>
          <span v-if="scope.row.reason == 6">门店护理体验不满</span>
          <span v-if="scope.row.reason == 7">购买产品或项目不满</span>
        </template>
      </el-table-column>
      <el-table-column prop="memberId" label="售后描述" show-overflow-tooltip>
        <template #default="scope">
          <span v-if="scope.row.reasonDescribe == 51">门店人员服务态度不佳</span>
          <span v-else-if="scope.row.reasonDescribe == 52">接待人员沟通不满意</span>
          <span v-else-if="scope.row.reasonDescribe == 61">护理效果未达到预期</span>
          <span v-else-if="scope.row.reasonDescribe == 62">护理后出现异常</span>
          <span v-else-if="scope.row.reasonDescribe == 11">更换工作交通不便</span>
          <span v-else-if="scope.row.reasonDescribe == 12">搬家交通不便</span>
          <span v-else-if="scope.row.reasonDescribe == 13">离开所在城市</span>
          <span v-else-if="scope.row.reasonDescribe == 14">个人经济原因</span>
          <span v-else-if="scope.row.reasonDescribe == 15">家庭原因</span>
          <span v-else-if="scope.row.reasonDescribe == 16 || scope.row.reasonDescribe == 53 || scope.row.reasonDescribe == 63 || scope.row.reasonDescribe == 71">其他原因</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="售后详情" show-overflow-tooltip>
        <template #default="scope">
          {{ scope.row.reasonOther || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="creationTime" :formatter="formatCreationTime" label="提交时间" width="160" show-overflow-tooltip />
      <el-table-column prop="lastConsumeShopName" label="最近消费门店" show-overflow-tooltip />
      <el-table-column label="状态">
        <template #default="scope">
          <span v-if="scope.row.status == 0"><el-tag type="warning">待处理</el-tag></span>
          <span v-if="scope.row.status == 1"><el-tag type="success">同意退卡</el-tag></span>
          <span v-if="scope.row.status == 2"><el-tag type="info">取消退卡</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template #default="scope">
          <el-button type="primary" size="small" @click="lookDetailFun('deal', scope.row)" v-if="scope.row.status == 0">处理</el-button>
          <el-button type="primary" plain size="small" @click="lookDetailFun('look', scope.row)" v-else>查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="paginationBox">
      <Pagination v-model:page="queryParams.data.page" v-model:size="queryParams.data.pageSize" :total="total" @pagination="getTabList" />
    </div>
  </div>
</template>

<script setup name="ReturnCard">
  import { ref, reactive, onMounted } from 'vue'
  import * as XLSX from "xlsx"
  import { ElMessage } from "element-plus"
  import { useRouter } from "vue-router"
  import { returnList, returnCardExport, shopDetail } from "@/http/returnCard"
  import { FullTimeFormat } from '@/utils/common'
  import Pagination from '@/components/pagination/index.vue'
  import zhCn from "element-plus/lib/locale/lang/zh-cn"

  const now = new Date();
  const date = now.toLocaleDateString().split('/');
  date[1] < 10 ? date[1] = `0${date[1]}` : date[1] = date[1]
  date[2] < 10 ? date[2] = `0${date[2]}` : date[2] = date[2]
  let createTime = ref([`${date[0]}-${date[1]}-${date[2]} 00:00:00`, `${date[0]}-${date[1]}-${date[2]} 23:59:59`])
  onMounted(() => {
    getTabList()
    getShopTree()
  })
  // 筛选条件
  let queryParams = reactive({
    data: {
      contactName: '', // 联系姓名
      contactMobile: '', // 联系手机号
      memberId: '', // 会员ID
      reason: null, // 退卡原因
      status: null, // 处理状态
      submitTimeStart: createTime.value[0], // 开始日期
      submitTimeEnd:  createTime.value[1], // 结束日期
      shopIds: [],
      gradeNames: [],
      page: 1,
      pageSize: 10
    }
  })
  let reasonList = ref([
    { key: '个人原因', value: 1 },
    { key: '服务不佳', value: 2 },
    { key: '产品或项目问题', value: 3 },
    { key: '未达到预期效果', value: 4 },
    { key: '门店接待服务不满', value: 5 },
    { key: '门店护理体验不满', value: 6 },
    { key: '购买产品或项目不满', value: 7 }
  ])
  let statusList = ref([
    { key: '待处理', value: 0 },
    { key: '同意退卡', value: 1 },
    { key: '取消退卡', value: 2 }
  ])
  function timeChange(value) {
    if(value) {
      queryParams.data.submitTimeStart = value[0]
      queryParams.data.submitTimeEnd = value[1]
    } else {
      queryParams.data.submitTimeStart = ''
      queryParams.data.submitTimeEnd = ''
    }
  }
  // table
  let tableLoading = ref(false)
  let returnCardList = ref([])
  let total = ref(0)
  function getTabList() {
    queryParams.data.shopIds = searchShopValue.value
    tableLoading.value = true
    returnList(queryParams.data).then(res => {
      tableLoading.value = false
      returnCardList.value = res.items
      total.value = res.totalCount
    }).catch(() => {
      tableLoading.value = false
    })
  }
  // 级联选择初始化
  let refreshItem = ref(0)
  function cascaderInit() {
    refreshItem.value++
  }
  function resetTable() {
    createTime.value = ''
    queryParams.data.contactName = ''
    queryParams.data.contactMobile = ''
    queryParams.data.memberId = ''
    queryParams.data.reason = null
    queryParams.data.status = null
    queryParams.data.gradeNames = []
    searchShopValue.value = []
    shopValue.value = []
    queryParams.data.submitTimeStart = ''
    queryParams.data.submitTimeEnd = ''
    queryParams.data.page = 1
    queryParams.data.pageSize = 10
    getTabList()
    cascaderInit()
  }
  function formatCreationTime(row) {
    return FullTimeFormat(row.creationTime)
  }
  // 查看/处理
  const router = useRouter()
  function lookDetailFun(type, row) {
    router.push({ 
      name: "ReturnCardDetail", 
      query: {
        type: type,
        id: row.id
      } 
    })
  }
  //导出表格为excel
  function exportExcel() {
    if(!queryParams.data.submitTimeStart || !queryParams.data.submitTimeEnd) return ElMessage.warning({
      message: '筛选日期不能为空',
      duration: 2000
    })
    exportData()
  };
  function exportData() {
    let data = {
      contactName: queryParams.data.contactName,
      contactMobile: queryParams.data.contactMobile,
      memberId: queryParams.data.memberId,
      reason: queryParams.data.reason,
      status: queryParams.data.status,
      submitTimeStart: queryParams.data.submitTimeStart,
      submitTimeEnd: queryParams.data.submitTimeEnd,
      shopIds: queryParams.data.shopIds,
      gradeNames: queryParams.data.gradeNames
    }
    returnCardExport(data).then((res) => {
      if(!res.eValues) return ElMessage.warning({
        message: '导出数据为空，请重新进行数据筛选',
        duration: 2000
      })
      let reg = /\\/g
      let replaceAfter = res.eValues.replace(reg, "")
      var jsondata = JSON.parse(replaceAfter)
      let time = new Date().getTime()
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(jsondata)
      XLSX.utils.book_append_sheet(workbook, worksheet, "data")
      XLSX.writeFile(workbook, `客户退卡列表_${time}.xlsx`)
    })
  }
  const shopValue = ref([])
  const searchShopValue = ref([])
  const options = ref([]) // 城市门店 树
  const leaveList = ref([]) // 等级

  const props = {
    multiple: true
  }
  function handleChange () {
    searchShopValue.value = []
    shopValue.value.forEach(val => {
      searchShopValue.value.push(val[val.length - 1])
    })
  }
  function getShopTree() {
    shopDetail().then(res => {
      options.value = res.data.shopTree
      leaveList.value = res.data.gradeNames
    })
  }
</script>

<style lang="scss" scoped>
.exportBox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
::v-deep {
  .paginationBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next {
      padding: 8px !important;
    }
  }
}
</style>